import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [univ, setUniv] = useState('');

    // 초기 로드 시 localStorage에서 univ 값을 가져옴
    useEffect(() => {
        const savedUniv = localStorage.getItem('univ');
        if (savedUniv) {
            setUniv('');
        }
    }, []);

    // 창을 닫았을 때 로컬스토리지에서 univ 값을 제거
    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('univ');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setUniv(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('univ', univ);  // 로컬스토리지에 univ 값을 저장
        setUniv(''); // input 필드를 비움
        navigate('/main-page', { state: { univ: localStorage.getItem('univ') } });
    };
    

    return (
        <header className="header">
            <div className='header-container'>
                <div className="header-meaning-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="26" viewBox="0 0 98 26" fill="none" className='header-meaning-logo'>
                        <path d="M91.0854 17.2667C91.8844 17.2667 92.6117 17.0396 93.2673 16.5854C93.9434 16.1312 94.4659 15.5222 94.8346 14.7583C95.2239 13.9944 95.4185 13.1582 95.4185 12.2498C95.4185 11.362 95.2239 10.5362 94.8346 9.77229C94.4454 8.98775 93.9229 8.36837 93.2673 7.91416C92.6117 7.45996 91.8844 7.23285 91.0854 7.23285C89.8151 7.23285 88.7703 7.70771 87.9508 8.65742C87.1517 9.58648 86.7522 10.7839 86.7522 12.2498C86.7522 13.7363 87.1517 14.9441 87.9508 15.8731C88.7498 16.8022 89.7947 17.2667 91.0854 17.2667ZM84.2015 12.2808C84.2015 10.8768 84.4883 9.61745 85.062 8.50257C85.6561 7.3877 86.4654 6.52057 87.4898 5.90119C88.5142 5.26117 89.682 4.94116 90.9932 4.94116C91.8742 4.94116 92.7142 5.16827 93.5132 5.62247C94.3122 6.05604 94.9473 6.65477 95.4185 7.41866V4.94116H98V18.4745C98 19.8784 97.7029 21.1482 97.1088 22.2837C96.5351 23.4398 95.7054 24.3483 94.6195 25.0089C93.5541 25.6696 92.3249 25.9999 90.9317 25.9999C89.7229 25.9999 88.6064 25.7212 87.582 25.1638C86.5576 24.6063 85.6971 23.7702 85.0005 22.6553L87.0903 21.3236C88.0532 22.8927 89.3337 23.6773 90.9317 23.6773C92.3044 23.6773 93.3902 23.1818 94.1893 22.1908C95.0088 21.2204 95.4185 20.0023 95.4185 18.5365V16.988C94.9063 17.7519 94.2405 18.3713 93.421 18.8461C92.6015 19.321 91.7922 19.5584 90.9932 19.5584C89.682 19.5584 88.5142 19.2487 87.4898 18.6294C86.4654 18.01 85.6561 17.1532 85.062 16.059C84.4883 14.9441 84.2015 13.6847 84.2015 12.2808Z" fill="#303030"/>
                        <path d="M79.0937 11.1103C79.0937 9.95417 78.7863 9.0251 78.1717 8.32314C77.5571 7.62118 76.7376 7.27021 75.7132 7.27021C74.6068 7.27021 73.6849 7.65215 72.9473 8.41605C72.2098 9.1593 71.841 10.0574 71.841 11.1103V19.41H69.2903V4.97852H71.841V7.08439C72.3327 6.46502 72.9473 5.95919 73.6849 5.56692C74.4225 5.17465 75.1395 4.97852 75.8361 4.97852C76.9015 4.97852 77.8746 5.21594 78.7556 5.6908C79.6366 6.16565 80.3332 6.85729 80.8454 7.76571C81.378 8.65348 81.6444 9.70641 81.6444 10.9245V19.41H79.0937V11.1103Z" fill="#303030"/>
                        <path d="M65.594 4.94173V19.4042H63.0433V4.94173H65.594ZM62.7667 1.54844C62.7667 1.09423 62.9101 0.722604 63.197 0.433562C63.5043 0.14452 63.8833 0 64.334 0C64.7848 0 65.1535 0.14452 65.4404 0.433562C65.7272 0.722604 65.8706 1.09423 65.8706 1.54844C65.8706 2.00265 65.7272 2.3846 65.4404 2.69428C65.1535 2.98333 64.7848 3.12785 64.334 3.12785C63.8833 3.12785 63.5043 2.98333 63.197 2.69428C62.9101 2.3846 62.7667 2.00265 62.7667 1.54844Z" fill="#303030"/>
                        <path d="M57.1977 11.1103C57.1977 9.95417 56.8904 9.0251 56.2758 8.32314C55.6611 7.62118 54.8416 7.27021 53.8172 7.27021C52.7109 7.27021 51.789 7.65215 51.0514 8.41605C50.3138 9.1593 49.9451 10.0574 49.9451 11.1103V19.41H47.3943V4.97852H49.9451V7.08439C50.4368 6.46502 51.0514 5.95919 51.789 5.56692C52.5265 5.17465 53.2436 4.97852 53.9402 4.97852C55.0055 4.97852 55.9787 5.21594 56.8597 5.6908C57.7406 6.16565 58.4372 6.85729 58.9494 7.76571C59.4821 8.65348 59.7484 9.70641 59.7484 10.9245V19.41H57.1977V11.1103Z" fill="#303030"/>
                        <path d="M30.3413 19.5338C29.0096 19.5338 27.8213 19.2242 26.7765 18.6048C25.7521 17.9854 24.9428 17.1286 24.3487 16.0344C23.775 14.9195 23.4882 13.6601 23.4882 12.2562C23.4882 10.8523 23.775 9.60319 24.3487 8.50896C24.9428 7.39408 25.7521 6.52695 26.7765 5.90758C27.8213 5.2882 29.0096 4.97852 30.3413 4.97852C32.2057 4.97852 33.5886 5.44305 34.4901 6.37211C35.3915 7.30117 35.9754 8.14765 36.2418 8.91155C36.6515 9.98513 36.8564 11.1619 36.8564 12.442C36.8564 12.8136 36.8462 13.0614 36.8257 13.1852C36.8257 13.2885 36.8257 13.3504 36.8257 13.3711H26.0696C26.254 14.5066 26.7457 15.446 27.5448 16.1892C28.3438 16.9118 29.276 17.2731 30.3413 17.2731C32.1647 17.2731 33.5272 16.5608 34.4286 15.1363L36.4569 16.1892C36.0472 17.0976 35.3096 17.8822 34.2442 18.5428C33.1994 19.2035 31.8984 19.5338 30.3413 19.5338ZM34.3364 11.1413C34.0086 8.53992 32.6769 7.23924 30.3413 7.23924C29.2555 7.23924 28.313 7.61086 27.514 8.35411C26.7355 9.07672 26.254 10.0161 26.0696 11.1723L34.3364 11.1413Z" fill="#303030"/>
                        <path d="M18.1009 10.8006C18.1009 9.74771 17.8243 8.90123 17.2711 8.26121C16.718 7.60054 15.9907 7.27021 15.0892 7.27021C14.0648 7.27021 13.2248 7.57989 12.5692 8.19927C11.9341 8.798 11.6165 9.58254 11.6165 10.5529V19.41H9.03508V10.8006C9.03508 9.74771 8.7585 8.90123 8.20533 8.26121C7.67265 7.60054 6.96582 7.27021 6.08485 7.27021C5.06046 7.27021 4.21022 7.64183 3.53413 8.38508C2.87852 9.12833 2.55072 10.0264 2.55072 11.0794V19.41H0V4.97852H2.55072V7.05342C2.98096 6.43405 3.52389 5.93855 4.17949 5.56692C4.8351 5.17465 5.50095 4.97852 6.17704 4.97852C7.05802 4.97852 7.89801 5.22627 8.69703 5.72177C9.51654 6.21727 10.1312 6.85729 10.5409 7.64183C11.0121 6.85729 11.678 6.21727 12.5385 5.72177C13.399 5.22627 14.2697 4.97852 15.1507 4.97852C16.216 4.97852 17.1687 5.21594 18.0087 5.6908C18.8487 6.16565 19.5043 6.83664 19.9755 7.70377C20.4467 8.55025 20.6823 9.5206 20.6823 10.6148V19.41H18.1009V10.8006Z" fill="#303030"/>
                        <ellipse cx="42.1624" cy="15.8536" rx="1.88787" ry="1.90244" fill="#87BAE1"/>
                        <ellipse cx="42.1624" cy="8.87803" rx="1.88787" ry="1.90244" fill="#87BAE1"/>
                    </svg>                
                </div>
                <div className="header-right-container">
                    <form className="header-search-form" onSubmit={handleSearchSubmit}>
                        <input
                            type="text"
                            placeholder="대학 이름을 검색하시면 대학별 인재를 검색하실 수 있어요!"
                            className="header-search-input"
                            value={univ}
                            onChange={handleSearchChange}
                        />
                        <button type="submit" className="header-search-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M17.7656 16.6895L12.6934 11.6172C13.4805 10.5996 13.9062 9.35547 13.9062 8.04688C13.9062 6.48047 13.2949 5.01172 12.1895 3.9043C11.084 2.79687 9.61133 2.1875 8.04688 2.1875C6.48242 2.1875 5.00977 2.79883 3.9043 3.9043C2.79687 5.00977 2.1875 6.48047 2.1875 8.04688C2.1875 9.61133 2.79883 11.084 3.9043 12.1895C5.00977 13.2969 6.48047 13.9062 8.04688 13.9062C9.35547 13.9062 10.5977 13.4805 11.6152 12.6953L16.6875 17.7656C16.7024 17.7805 16.72 17.7923 16.7395 17.8004C16.7589 17.8084 16.7797 17.8126 16.8008 17.8126C16.8218 17.8126 16.8427 17.8084 16.8621 17.8004C16.8815 17.7923 16.8992 17.7805 16.9141 17.7656L17.7656 16.916C17.7805 16.9011 17.7923 16.8835 17.8004 16.864C17.8084 16.8446 17.8126 16.8238 17.8126 16.8027C17.8126 16.7817 17.8084 16.7609 17.8004 16.7414C17.7923 16.722 17.7805 16.7043 17.7656 16.6895ZM11.1406 11.1406C10.3125 11.9668 9.21484 12.4219 8.04688 12.4219C6.87891 12.4219 5.78125 11.9668 4.95312 11.1406C4.12695 10.3125 3.67188 9.21484 3.67188 8.04688C3.67188 6.87891 4.12695 5.7793 4.95312 4.95312C5.78125 4.12695 6.87891 3.67188 8.04688 3.67188C9.21484 3.67188 10.3145 4.125 11.1406 4.95312C11.9668 5.78125 12.4219 6.87891 12.4219 8.04688C12.4219 9.21484 11.9668 10.3145 11.1406 11.1406Z" fill="#57A0D6"/>
                            </svg>                   
                        </button>
                    </form>
                    <button className="header-login-button">회원가입/로그인</button>
                </div>
            </div>
            <div className="header-bottom-line"></div>
        </header>
    );
};

export default Header;
