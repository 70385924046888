// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the footer is always at the bottom of the page */
.univ-logos {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    flex-shrink: 0;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.univ-logos-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0px;
}

.logo {
    height: 40px; /* Adjust logo size */
}

.logo-smwu {
    height: 50px;
}

.logo-hiu,
.logo-cnu {
    height: 35px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/UnivLogos.css"],"names":[],"mappings":"AAAA,0DAA0D;AAC1D;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,YAAY,EAAE,qBAAqB;AACvC;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":["/* Ensure the footer is always at the bottom of the page */\n.univ-logos {\n    width: 100%;\n    height: fit-content;\n    flex-shrink: 0;\n    color: #fff;\n    text-align: center;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.univ-logos-div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 24px;\n    margin: 0px;\n}\n\n.logo {\n    height: 40px; /* Adjust logo size */\n}\n\n.logo-smwu {\n    height: 50px;\n}\n\n.logo-hiu,\n.logo-cnu {\n    height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
