import React, { useState, useEffect } from 'react';
import '../styles/JobApplyButtons.css';

const JobApplyButtons = ({ buttons, containerStyle, buttonClassName, onApplyChange }) => {
    const [selectedButtons, setSelectedButtons] = useState(['전체', '인턴', '계약직', '정규직']);
    const [isFirstClickHandled, setIsFirstClickHandled] = useState(false); // 첫 클릭을 처리했는지 확인하는 상태

    useEffect(() => {
        handleApplyChange();
    }, [selectedButtons]);

    const handleButtonClick = (button) => {
        if (!isFirstClickHandled && selectedButtons.length === 4 && selectedButtons.includes('전체') && button !=='전체') {
            // 첫 클릭에서 '전체', '인턴', '계약직', '정규직'이 모두 선택된 경우
            setSelectedButtons([button]); // 클릭된 버튼만 선택 상태로 설정
            setIsFirstClickHandled(true); // 첫 클릭 처리가 완료되었음을 표시
        } else {
            if (button === '전체') {
                setSelectedButtons(['전체', '인턴', '계약직', '정규직']);
            } else {
                let updatedSelectedButtons;
                if (selectedButtons.includes(button)) {
                    updatedSelectedButtons = selectedButtons.filter((b) => b !== button);
                } else {
                    updatedSelectedButtons = [...selectedButtons, button];
                }

                if (
                    updatedSelectedButtons.includes('인턴') &&
                    updatedSelectedButtons.includes('계약직') &&
                    updatedSelectedButtons.includes('정규직')
                ) {
                    updatedSelectedButtons = ['전체', '인턴', '계약직', '정규직'];
                } else {
                    updatedSelectedButtons = updatedSelectedButtons.filter((b) => b !== '전체');
                }

                setSelectedButtons(updatedSelectedButtons);
            }
        }
    };

    const handleApplyChange = () => {
        const isIntern = selectedButtons.includes('인턴') ? 1 : 0;
        const isParttime = selectedButtons.includes('계약직') ? 1 : 0;
        const isFulltime = selectedButtons.includes('정규직') ? 1 : 0;

        onApplyChange(isIntern, isParttime, isFulltime);
    };

    return (
        <div className="job-apply-button-container" style={containerStyle}>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    className={`job-apply-button ${selectedButtons.includes(button) ? 'selected' : ''} ${buttonClassName || ''}`}
                    onClick={() => handleButtonClick(button)}
                >
                    {button}
                </button>
            ))}
        </div>
    );
};

export default JobApplyButtons;
