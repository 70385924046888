// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-without-result-icon {
    position: relative !important;
    width: 250px;
    /* 컨테이너의 전체 너비를 설정 */
    height: 300px;
    /* 컨테이너의 전체 높이를 설정 */
    margin-top: 40px;
    /* 컨테이너를 화면 중앙으로 정렬 */
}

.main-without-result-icon-person {
    width: 220.661px;
    height: 221.209px;
    flex-shrink: 0;

    position: absolute;
    top: 8;
    left: 0;
}

.main-without-result-icon-bubble {
    width: 113.801px;
    height: 67.404px;
    flex-shrink: 0;

    position: absolute;
    top: 7px;
    left: 143px;
}

.main-without-result-icon-qmark {
    width: 24.295px;
    height: 35.985px;
    flex-shrink: 0;

    position: absolute;
    top: 24px;
    left: 205px;
}`, "",{"version":3,"sources":["webpack://./src/styles/MainPageWithNoResultIcon.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,oBAAoB;IACpB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;;IAEd,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;;IAEd,kBAAkB;IAClB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;;IAEd,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".main-without-result-icon {\n    position: relative !important;\n    width: 250px;\n    /* 컨테이너의 전체 너비를 설정 */\n    height: 300px;\n    /* 컨테이너의 전체 높이를 설정 */\n    margin-top: 40px;\n    /* 컨테이너를 화면 중앙으로 정렬 */\n}\n\n.main-without-result-icon-person {\n    width: 220.661px;\n    height: 221.209px;\n    flex-shrink: 0;\n\n    position: absolute;\n    top: 8;\n    left: 0;\n}\n\n.main-without-result-icon-bubble {\n    width: 113.801px;\n    height: 67.404px;\n    flex-shrink: 0;\n\n    position: absolute;\n    top: 7px;\n    left: 143px;\n}\n\n.main-without-result-icon-qmark {\n    width: 24.295px;\n    height: 35.985px;\n    flex-shrink: 0;\n\n    position: absolute;\n    top: 24px;\n    left: 205px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
