import React from 'react';
import kuLogo from '../assets/logos/KU_symbol.gif';
import snuLogo from '../assets/logos/snu_ui_logo.svg';

const DetailpageUnivLogos = ({ university, className }) => {
    const extractUniversity = (univMajor) => {
        if (univMajor) {
            const univName = univMajor.split(' ')[0];
            return univName;
        }
        return '';
    };

    const univName = extractUniversity(university);
    let logo = null;

    switch (univName) {
        case '고려대학교':
            logo = kuLogo;
            break;
        case '서울대학교':
            logo = snuLogo;
            break;
        default:
            logo = null;
            break;
    }

    return logo ? <img src={logo} alt={`${univName} Logo`} className={className} /> : null;
};

export default DetailpageUnivLogos;
