// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-apply-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


/* unselected 일 때 css */
.job-apply-button {
    cursor: pointer;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

    border-radius: 20px;
    border: 1px solid var(--GrayScale_CommonGray, #CECECE);
    background: var(--GrayScale_NormalGray, #F2F2F2);

    color: var(--GrayScale_Gray_06, #A9A9A9);
    text-align: center;
    font-family: Pretendard;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    /* 22px */
}

.job-apply-button:hover,
.job-apply-button.selected:hover {
    border-radius: 20px;
    border: 1px solid var(--meaning-blue-normalblue, #57A0D6);
    background: var(--meaning-blue-lightblue, #EFF9FF);
}

.job-apply-button.selected {
    border-radius: 20px;
    border: 1px solid var(--GrayScale_CommonGray, #CECECE);
    background: #FFF;

    color: var(--GrayScale_Gray_07, #434343);
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/JobApplyButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;;;AAGA,uBAAuB;AACvB;IACI,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,uBAAuB;IACvB,SAAS;;IAET,mBAAmB;IACnB,sDAAsD;IACtD,gDAAgD;;IAEhD,wCAAwC;IACxC,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;AACb;;AAEA;;IAEI,mBAAmB;IACnB,yDAAyD;IACzD,kDAAkD;AACtD;;AAEA;IACI,mBAAmB;IACnB,sDAAsD;IACtD,gBAAgB;;IAEhB,wCAAwC;IACxC,gBAAgB;AACpB","sourcesContent":[".job-apply-button-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n}\n\n\n/* unselected 일 때 css */\n.job-apply-button {\n    cursor: pointer;\n    display: flex;\n    padding: 4px 12px;\n    justify-content: center;\n    align-items: flex-start;\n    gap: 10px;\n\n    border-radius: 20px;\n    border: 1px solid var(--GrayScale_CommonGray, #CECECE);\n    background: var(--GrayScale_NormalGray, #F2F2F2);\n\n    color: var(--GrayScale_Gray_06, #A9A9A9);\n    text-align: center;\n    font-family: Pretendard;\n    font-size: 11px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 200%;\n    /* 22px */\n}\n\n.job-apply-button:hover,\n.job-apply-button.selected:hover {\n    border-radius: 20px;\n    border: 1px solid var(--meaning-blue-normalblue, #57A0D6);\n    background: var(--meaning-blue-lightblue, #EFF9FF);\n}\n\n.job-apply-button.selected {\n    border-radius: 20px;\n    border: 1px solid var(--GrayScale_CommonGray, #CECECE);\n    background: #FFF;\n\n    color: var(--GrayScale_Gray_07, #434343);\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
