import React from 'react';
import '../styles/UnivLogos.css';
import SNU_logo from '../assets/logos/SNU_logo.png';
import KU_logo from '../assets/logos/KU_logo.gif';
import SKKU_logo from '../assets/logos/SKKU_logo.svg';
import SMWU_logo from '../assets/logos/SMWU_logo.png';
import CNU_logo from '../assets/logos/CNU_logo.png';
import HIU_logo from '../assets/logos/HIU_logo.svg';

const UnivLogos = () => {
    return (
        <div className="univ-logos">
            <div className="univ-logos-div">
                <img src={SNU_logo} alt="서울대학교" className="logo" />
                <img src={KU_logo} alt="고려대학교" className="logo" />
                <img src={SKKU_logo} alt="성균관대학교" className="logo" />
                <img src={HIU_logo} alt="홍익대학교" className='logo-hiu' />
                <img src={SMWU_logo} alt="숙명여자대학교" className="logo-smwu" />
                <img src={CNU_logo} alt="전남대학교" className="logo-cnu" />
            </div>
        </div>
    );
};

export default UnivLogos;
