import React, { useState, useEffect, useRef } from 'react';
import '../styles/JobFieldSelectBoxLarge.css';

const JobFieldSelectBoxLarge = ({ options, selectedValue, onChange, placeholder, message }) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectBoxRef = useRef(null);

    const handleSelect = (value) => {
        onChange(value);
        setIsOpen(false);
    };

    // 클릭한 곳이 select box 외부일 경우 dropdown을 닫는 효과
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-select-large" ref={selectBoxRef}>
            <div className="select-large-selected" onClick={() => setIsOpen(!isOpen)}>
                {selectedValue || placeholder}
            </div>
            {isOpen && (
                <div className="select-large-items">
                    {options.length > 0 ? (
                        options.map((option) => (
                            <div key={option} onClick={() => handleSelect(option)}>
                                {option}
                            </div>
                        ))
                    ) : (
                        <div className="select-large-placeholder">{message}</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default JobFieldSelectBoxLarge;
