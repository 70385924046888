import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import apiClient from '../api/apiClient';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProfileBoxMainCareer from '../components/ProfileBoxMainCareer';
import DetailCareerBox from '../components/DetailCareerBox';
import SelectedComponent from '../components/SelectedComponent';
import UnselectedComponent from '../components/UnselectedComponent';
import DropDownComponent from '../components/DropDownComponent';
import '../styles/DetailsPage.css';

const DetailsPage = () => {
    const { state } = useLocation();
    const { selectedProfile = {}, profiles = [], logo } = state || {};
    
    const [selectedId, setSelectedId] = useState(() => {
        const foundIndex = profiles.findIndex(p => p.person.p_id === selectedProfile.person.p_id);
        return foundIndex >= 0 ? foundIndex : null;
    });

    const [sortMethod, setSortMethod] = useState('주요 경험 순');
    const [detailedData, setDetailedData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const selectedPerson = selectedId !== null && selectedId < profiles.length ? profiles[selectedId] : selectedProfile;
    const selectedRef = useRef(null);

    useEffect(() => {
        profiles.forEach(profile => {
            if (profile.person && !detailedData[profile.person.p_id]) {
                fetchDetailedData(profile.person.p_id);
            }
        });
    }, [profiles, detailedData]);

    useEffect(() => {
        if (selectedPerson && selectedPerson.person) {
            fetchDetailedData(selectedPerson.person.p_id);
        }
    }, [selectedPerson, sortMethod]);

    const fetchDetailedData = async (p_id) => {
        setLoading(true);
        setError(null);
        try {
            const response = await apiClient.post('/details', {
                p_id,
                sortMethod,
                keywords: profiles.find(p => p.person.p_id === p_id)?.keywords.map(keyword => ({
                    k_id: keyword.k_id,
                    k_name: keyword.k_name,
                    details: keyword.details.map(detail => detail.c_id)
                })) || []
            });
            if (response.data) {
                setDetailedData(prevData => ({
                    ...prevData,
                    [p_id]: response.data
                }));
            } else {
                setError('No data received from server.');
            }
        } catch (error) {
            console.error('Error fetching detailed data:', error);
            setError('Failed to fetch detailed data.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (id) => {
        if (id === null) {
            setSelectedId(null);
        } else {
            setSelectedId(id);
            const selectedProfileId = profiles[id]?.person?.p_id;
            if (selectedProfileId && !detailedData[selectedProfileId]) {
                fetchDetailedData(selectedProfileId);
            }

            // 컴포넌트를 클릭한 후 스크롤을 조정하여 selectedComponent가 최상단에 위치하도록 함
            setTimeout(() => {
                if (selectedRef.current) {
                    selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100); // DOM이 완전히 업데이트된 후 스크롤 실행
        }
    };

    const handleSortChange = (option) => {
        setSortMethod(option);
    };

    const sortActivities = (activities) => {
        if (sortMethod === '주요 경험 순') {
            return activities.sort((a, b) => b.l_id - a.l_id);
        } else if (sortMethod === '최신 경험 순') {
            return activities.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        }
        return activities;
    };

    const getInternStatus = (activity) => {
        const today = new Date();
        const endDate = new Date(activity.endDate);
    
        if (endDate > today) {
            return '현'; // 진행 중인 활동
        } else {
            return '전'; // 마무리된 활동
        }
    };
    
    const getFirstActivityName = (data) => {
        if (data && data.keywords.length > 0) {
            const firstKeyword = data.keywords[0];
            if (firstKeyword.details.length > 0) {
                return firstKeyword.details[0].c_name || '활동명 없음';
            }
        }
        return '활동명 없음';
    };

    const selectedProfileData = selectedId !== null && selectedPerson ? detailedData[selectedPerson.person.p_id] : null;

    const renderProfiles = () => {
        return profiles.map((person, index) => {
            if (selectedId === index) {
                return (
                    <SelectedComponent
                        key={index}
                        category={person?.person?.jobName || '직무 없음'}
                        nickname={person?.person?.nickname}
                        univMajor={`${person?.person?.univ} ${person?.person?.major}`}
                        internStatus={
                            detailedData[person.person.p_id]?.keywords?.length > 0 
                            ? getInternStatus(detailedData[person.person.p_id].keywords[0].details[0])
                            : '수료'
                        }
                        internDetails={getFirstActivityName(detailedData[person.person.p_id])}
                        resume={person?.person?.resume}
                        onClick={() => handleSelect(null)}
                        ref={selectedRef}
                    />
                );
            } else {
                return (
                    <UnselectedComponent
                        key={index}
                        category={person?.person?.jobName || '직무 없음'}
                        nickname={person?.person?.nickname}
                        univMajor={`${person?.person?.univ} ${person?.person?.major}`}
                        internStatus={
                            detailedData[person.person.p_id]?.keywords?.length > 0 
                            ? getInternStatus(detailedData[person.person.p_id].keywords[0].details[0])
                            : '수료'
                        }
                        internDetails={getFirstActivityName(detailedData[person.person.p_id])}
                        resume={person?.person?.resume}
                        onSelect={() => handleSelect(index)}
                    />
                );
            }
        });
    };

    return (
        <div className="details-page">
            <Header />
            <main className="content">
                <div className="details-container">
                    <div className="left-frame">
                        {renderProfiles()}
                    </div>
                    <div className="right-frame">
                        {selectedPerson && selectedPerson.person ? (
                            <ProfileBoxMainCareer
                                jobName={selectedPerson.person.jobName || '직무 없음'}
                                nickname={selectedPerson.person.nickname}
                                univMajor={`${selectedPerson.person.univ} ${selectedPerson.person.major}`}
                                mainMajor={selectedPerson.person.dmajor}
                                gradDate={selectedPerson.person.gradDate}
                                gradStatus={selectedPerson.person.gradStatus}
                                otherUnivMajor={selectedPerson.person.minor}
                                apply_Intern={selectedPerson.person.apply_Intern}
                                apply_Parttime={selectedPerson.person.apply_Parttime}
                                apply_Fulltime={selectedPerson.person.apply_Fulltime}
                                resume={selectedPerson.person.resume}
                                careers={selectedPerson.keywords.flatMap(keyword =>
                                    keyword.details.filter(detail => detail.l_id >= 308 && detail.l_id <= 315)
                                )}
                            />
                        ) : (
                            <div className="no-profile-selected">
                                <p>프로필을 선택해주세요.</p>
                            </div>
                        )}
                        <div className="experience-section">
                            <div className="experience-header">
                                <h2>경험 살펴보기</h2>
                                <DropDownComponent onSelect={handleSortChange} />
                            </div>
                            {selectedProfileData?.keywords?.map((keyword, index) => (
                                <div key={index}>
                                    <div className="experience-content">
                                        <div className="experience-item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.625 6.99375H12.2109L9.46172 4.36406C9.42674 4.33132 9.38073 4.31291 9.33281 4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V18.9375C2.625 19.3523 2.96016 19.6875 3.375 19.6875H20.625C21.0398 19.6875 21.375 19.3523 21.375 18.9375V7.74375C21.375 7.32891 21.0398 6.99375 20.625 6.99375ZM19.6875 18H4.3125V6H8.73047L11.5336 8L68125H19.6875V18Z" fill="#378BCA"/>
                                            </svg>
                                            <span className="experience-title">{keyword.k_name}</span>
                                            <span className="experience-count">{keyword.details.length}건</span>
                                        </div>
                                    </div>
                                    
                                    {sortActivities(keyword.details).map((activity, index) => (
                                        <div className='DetailCareerBox-section' key={index}>
                                            <DetailCareerBox
                                                dateRange={`${activity.startDate} - ${activity.endDate}`}
                                                title={activity.c_name}
                                                status={activity.l_name}
                                                workContent={activity.DoneWorks?.join(', ') || '업무 내용이 없습니다.'}
                                                achievements={activity.Achievements?.join(', ') || '성과 내용이 없습니다.'}
                                                contributions={activity.Contributions?.join(', ') || '기여 내용이 없습니다.'}
                                                learnings={activity.Learnings?.join(', ') || '배운 점이 없습니다.'}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default DetailsPage;
