import React from 'react';
import '../styles/LandingIcon.css';

// SVG 파일들을 import
import landing_left_bubble from '../assets/icons/landing_left_bubble.svg';
import landing_left_bulb from '../assets/icons/landing_left_bulb.svg';
import landing_left from '../assets/icons/landing_left.svg';
import landing_right_hand from '../assets/icons/landing_right_hand.svg';
import landing_right_heart from '../assets/icons/landing_right_heart.svg';
import landing_right_magnet from '../assets/icons/landing_right_magnet.svg';
import landing_right from '../assets/icons/landing_right.svg';
import landing_center from '../assets/icons/landing_center.svg';
import landing_center_chart from '../assets/icons/landing_center_chart.svg';
import landing_center_hand from '../assets/icons/landing_center_hand.svg'

const LandingIcon = () => {
    return (
        <div className="landing-icon-container">
            <div className='landing-icon-left'>
                <img src={landing_left_bubble} alt="Landing Left Bubble" className="landing_left_bubble" />
                <img src={landing_left_bulb} alt="Landing Left Bulb" className="landing_left_bulb" />
                <img src={landing_left} alt="Landing Left" className="landing_left" />
            </div>
            <div className='landing-icon-right'>
                <img src={landing_right_hand} alt="Landing Right Hand" className="landing_right_hand" />
                <img src={landing_right_heart} alt="Landing Right Heart" className="landing_right_heart" />
                <img src={landing_right_magnet} alt="Landing Right Magnet" className="landing_right_magnet" />
                <img src={landing_right} alt="Landing Right" className="landing_right" />
            </div>
            <div className='landing-icon-center'>
                <img src={landing_center} alt="Landing Center" className="landing_center" />
                <img src={landing_center_chart} alt="Landing Center Chart" className="landing_center_chart" />
                <img src={landing_center_hand} alt="Landing Center Hand" className="landing_center_hand" />
            </div>
        </div>
    );
};

export default LandingIcon;
