import React, { useState } from 'react';
import DetailpageUnivLogos from './DetailpageUnivLogos';
import '../styles/UnselectedComponent.css';

const UnselectedComponent = React.forwardRef(({ nickname, univMajor, internStatus, internDetails, onSelect, selected }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const modifiedInternStatus = internStatus === '재학' ? '전' : internStatus;

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className={`unselected-component ${isHovered || selected ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onSelect}
            ref={ref}
        >
            <div className="unselected-content">
                <div className="unselected-content-header-written">
                    <span className="unselected-nickname">{nickname}</span>
                </div>
                <span className="unselected-image">
                    <DetailpageUnivLogos university={univMajor} className="unselected-image" />
                </span>
                <div className="unselected-content-details">
                    <span className="unselected-univ-major">{univMajor}</span>
                    <span className="unselected-intern-info">
                        <span className="unselected-intern-status">{modifiedInternStatus}</span>
                        <span className="unselected-intern-details">{internDetails}</span>
                    </span>
                </div>
            </div>
        </div>
    );
});

export default UnselectedComponent;
