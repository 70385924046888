import React from 'react';
import '../styles/ProfileCard.css';
import KU_logo from '../assets/logos/KU_symbol.gif';

const formatDateYYMM = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}.${month}`;
};

const formatDateYYYYMMDD = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
};

const ProfileCard = ({ nickname, keywords = [], univ, major, gradStatus, gradDate, jobName, apply_Intern, apply_Parttime, apply_Fulltime, mainCareer, onClick }) => {
    const employmentTypes = [];
    if (apply_Intern) employmentTypes.push('인턴');
    if (apply_Parttime) employmentTypes.push('계약');
    if (apply_Fulltime) employmentTypes.push('정규');

    let logo = null;
    if (univ === '고려대학교') {
        logo = KU_logo;
    } 
    // else if (univ === '서울대학교') {
    //     logo = SNU_logo;
    // }

    return (
        <div className="profile-card" onClick={onClick}>
            {logo && <img src={logo} alt={`${univ} Logo`} className="profile-card-logo" />}
            <div className="profile-card-header">
                <span className="profile-card-jobKeyword">{jobName}</span>
                <div className="profile-card-apply-tags">
                    {employmentTypes.map((type, index) => (
                        <span key={index} className="profile-card-apply-tag">{type}</span>
                    ))}
                </div>
            </div>
            <div className="profile-card-nickname">{nickname}</div>
            <div className="profile-card-keyword">
                {keywords.slice(0, 2).map((keyword, index) => (
                    <div key={index} className="profile-card-keywords-tag">
                        <span className='profile-card-keyword-name'>{keyword.k_name}</span>
                        <span className="profile-card-keyword-length">{keyword.details.length}건</span>
                    </div>
                ))}
            </div>
            <div className="profile-card-details">
                <div className="profile-card-details-upper">
                    <div className='profile-card-details-gradDate'>{formatDateYYYYMMDD(gradDate)}</div>
                    <div className='profile-card-details-gradText'>{univ} {major} {gradStatus}</div>
                </div>
                <div className="profile-card-details-lower">
                    <div className='profile-card-details-mainCareerDate'>{mainCareer ? `${formatDateYYMM(mainCareer.startDate)} ~ ${formatDateYYMM(mainCareer.endDate)}` : 'N/A'}</div>
                    <div className='profile-card-details-mainCareerText'>{mainCareer ? mainCareer.c_name : 'N/A'}</div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
