import React from 'react';
import DetailpageUnivLogos from './DetailpageUnivLogos';
import '../styles/ProfileBoxMainCareer.css';

const ProfileBoxMainCareer = ({
    jobName,
    nickname,
    univMajor,
    mainMajor,
    gradDate,
    gradStatus,
    otherUnivMajor,
    apply_Intern,
    apply_Parttime,
    apply_Fulltime,
    careers,
    resume
}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}.${month}`;
    };

    const employmentTypes = [];
    if (apply_Intern) employmentTypes.push('인턴');
    if (apply_Parttime) employmentTypes.push('계약');
    if (apply_Fulltime) employmentTypes.push('정규');

    return (
        <div className="component-box">
            <div className="header-section">
                <div className="header-section-written">
                    <span className="category">{jobName}</span>
                    <span className="nickname">{nickname}</span>
                </div>
                <div className="employment-types">
                    {employmentTypes.map((type, index) => (
                        <span key={index} className="employment-type">{type}</span>
                    ))}
                </div>
            </div>
            <div className="left-section">
                <DetailpageUnivLogos university={univMajor} className="image" />
            </div>
            <div className="content-section">
                <div className="univ-info">
                    <span className="title-grad-info">학력</span>
                    <div className="grad-info">
                        <span className="univ-major">{univMajor}</span>
                        {mainMajor && <div className="separator"></div>}
                        <span className="main-major">{mainMajor}</span>
                        <span className="grad-date">{gradDate}</span>
                        <span className="grad-status">{gradStatus}</span>
                    </div>
                    <span className="other-univ-major">{otherUnivMajor}</span>
                </div>
                {careers.length > 0 && (
                    <div className="main-career">
                        <span className="main-career-title">주요 이력</span>
                        {careers.map((career, index) => (
                            <div className="career-item" key={index}>
                                <span className="career-date">{formatDate(career.startDate)} ~ {formatDate(career.endDate)}</span>
                                <span className="career-title">{career.c_name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSerenKCc-rXMzz7ofE2MA7ota8veliwUnISc3ou_ir-eywNKw/viewform?usp=sf_link' target="_blank" rel="noopener noreferrer" className="bottom-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className="bottom-button-icon">
                    <path d="M18.125 3.625H1.875C1.5293 3.625 1.25 3.9043 1.25 4.25V16.75C1.25 17.0957 1.5293 17.375 1.875 17.375H18.125C18.4707 17.375 18.75 17.0957 18.75 16.75V4.25C18.75 3.9043 18.4707 3.625 18.125 3.625ZM17.3438 15.9688H2.65625V5.03125H17.3438V15.9688ZM11.875 8.70312C11.875 8.61719 11.8945 8.54688 11.9199 8.54688H14.3301C14.3555 8.54688 14.375 8.61719 14.375 8.70312V9.64062C14.375 9.72656 14.3555 9.79688 14.3301 9.79688H11.9199C11.8945 9.79688 11.875 9.72656 11.875 9.64062V8.70312ZM11.875 11.5156C11.875 11.4297 11.9375 11.3594 12.0137 11.3594H15.6406C15.7168 11.3594 15.7793 11.4297 15.7793 11.5156V12.4531C15.7793 12.5391 15.7168 12.6094 15.6406 12.6094H12.0137C11.9375 12.6094 11.875 12.5391 11.875 12.4531V11.5156ZM4.22266 13.4805C4.27734 12.4395 4.8457 11.5352 5.67969 11.0176C5.32617 10.627 5.11133 10.1113 5.11133 9.54297C5.11133 8.33398 6.08594 7.35547 7.28711 7.35547C8.48828 7.35547 9.46289 8.33398 9.46289 9.54297C9.46289 10.1113 9.24805 10.6289 8.89453 11.0176C9.72656 11.5332 10.2969 12.4395 10.3516 13.4805C10.3526 13.5016 10.3494 13.5228 10.342 13.5427C10.3346 13.5625 10.3233 13.5807 10.3087 13.596C10.294 13.6114 10.2765 13.6236 10.257 13.6319C10.2375 13.6403 10.2165 13.6446 10.1953 13.6445H9.33789C9.25586 13.6445 9.18945 13.5801 9.18359 13.498C9.10938 12.5117 8.28516 11.7305 7.28516 11.7305C6.28516 11.7305 5.46094 12.5117 5.38672 13.498C5.38086 13.5801 5.31445 13.6445 5.23242 13.6445H4.375C4.28516 13.6445 4.21484 13.5703 4.22266 13.4805Z" fill="#378BCA"/>
                </svg>
                <span className="bottom-button-text">이력서 받아보기</span>
            </a>
        </div>
    );
};

export default ProfileBoxMainCareer;
