import React from 'react';
import DetailpageUnivLogos from './DetailpageUnivLogos';
import '../styles/SelectedComponent.css';

const SelectedComponent = React.forwardRef(({ category, nickname, univMajor, internStatus, internDetails, onClick }, ref) => {
    const modifiedInternStatus = internStatus === '재학' ? '전' : internStatus;

    return (
        <div className="selected-component" onClick={onClick} ref={ref}>
            <div className="selected-content">
                <div className="selected-content-header-written">
                    <span className="selected-category">{category}</span>
                    <span className="selected-nickname">{nickname}</span>
                </div>
                <span className="selected-image">
                    <DetailpageUnivLogos university={univMajor} className="selected-image" />
                </span>
                <div className="selected-content-details">
                    <span className="selected-univ-major">{univMajor}</span>
                    <span className="selected-intern-info">
                        <span className="selected-intern-status">{modifiedInternStatus}</span>
                        <span className="selected-intern-details">{internDetails}</span>
                    </span>
                </div>
            </div>
        </div>
    );
});

export default SelectedComponent;
