import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import JobFieldSelectBox from '../components/JobFieldSelectBox';
import KeywordButtons from '../components/KeywordButtons';
import JobApplyButtons from '../components/JobApplyButtons';
import ProfileCard from '../components/ProfileCard';
import '../styles/MainPage.css';
import '../styles/LandingPage.css';
import apiClient from '../api/apiClient';

import KU_symbol from '../assets/logos/KU_symbol.gif';
import MainPageWithNoResult from '../components/MainPageWithNoResultIcon';

const MainPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { univ = '', selectedCategory = '', selectedSubCategory = '', keywordIds = [], categories = {} } = location.state || {};

    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [categoriesState, setCategoriesState] = useState(categories);
    const [selectedCategoryState, setSelectedCategoryState] = useState(selectedCategory);
    const [selectedSubCategoryState, setSelectedSubCategoryState] = useState(selectedSubCategory);
    const [isIntern, setIsIntern] = useState(1);
    const [isParttime, setIsParttime] = useState(1);
    const [isFulltime, setIsFulltime] = useState(1);
    const [univState, setUnivState] = useState(univ);
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);  // 로딩 상태 추가


    const fixedContainerRef = useRef(null);
    const peopleWrapperRef = useRef(null);

    

    
    // fixed-container 높이 계산 함수
    const updatePaddingTop = () => {
        if (fixedContainerRef.current && peopleWrapperRef.current) {
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            peopleWrapperRef.current.style.paddingTop = `${fixedContainerHeight + 81}px`; // 헤더 높이 81px 포함
        }
    };




    // 페이지 로드 시 selectField API 요청
    useEffect(() => {
        if (Object.keys(categoriesState).length === 0) {
            apiClient.get('/selectField')
                .then(response => {
                    const fieldData = response.data.fields;
                    const formattedCategories = {};
                    fieldData.forEach(field => {
                        formattedCategories[field.k_name] = field.subfields.map(subfield => ({
                            k_name: subfield.k_name,
                            keywords: subfield.keywords
                        }));
                    });
                    setCategoriesState(formattedCategories);
                })
                .catch(error => {
                    console.error('Error fetching field data:', error);
                });
        }
    }, []);




    // 카테고리 및 키워드 설정
    useEffect(() => {
        if (selectedCategory) {
            setSubCategories(categories[selectedCategory].map(sub => sub.k_name));
            const initialSelectedKeywords = categories[selectedCategory].find(sub => sub.k_name === selectedSubCategory)?.keywords.filter(keyword => keywordIds.includes(keyword.k_id)) || [];
            setSelectedKeywords(initialSelectedKeywords);
        }
        updatePaddingTop();
    }, [selectedCategory, selectedSubCategory, keywordIds, categories]);




    // filtering POST 요청
    useEffect(() => {
        if (selectedCategoryState && !selectedSubCategoryState) {
            return;
        }

        let keywordIds = selectedKeywords.map(keyword => keyword.k_id);

        if (selectedKeywords.length === 0) {
            if (selectedSubCategoryState) {
                const subCategory = categoriesState[selectedCategoryState]?.find(sub => sub.k_name === selectedSubCategoryState);
                if (subCategory) {
                    keywordIds = subCategory.keywords.map(keyword => keyword.k_id);
                }
            } else {
                keywordIds = [];
            }
        }

        // 고용 구분 모두 껐을 경우
        if (!isIntern && !isParttime && !isFulltime) {
            setProfiles([]);
            return;
        }

        // 학교 검색 & 직무 필터 모두 안 했을 경우
        if (!univ && keywordIds.length === 0) {
            setProfiles([]);
            return;
        }

        setIsLoading(true);  // API 요청 시작 시 로딩 상태로 변경

        // post 요청
        apiClient.post('/filtering', {
            univ: univ,
            keyword: keywordIds,
            isIntern: isIntern,
            isParttime: isParttime,
            isFulltime: isFulltime
        })
        .then(response => {
            const message = response.data.message;
            console.log('[filtering API 요청]');

            // console.log('[res.body] response message : ' + message);

            if (message) {
                // 학교가 일치하지 않는 경우 처리
                // console.log('[if 문 진입] response message : O');
                setResponseMessage((prevMessage) => 'NO' || prevMessage);  // prevMessage 대신 'NO' 설정
                setProfiles([]);
            } else if (response.data.users && response.data.users.length !== 0) {
                // 프로필이 존재하는 경우
                setResponseMessage((prevMessage) => 'YES' || prevMessage);  // prevMessage 대신 'YES' 설정
                setProfiles(response.data.users);
            } else {
                // 프로필이 존재하지 않지만 메시지가 없을 경우
                setResponseMessage((prevMessage) => 'YES' || prevMessage);  // prevMessage 대신 'YES' 설정
                setProfiles([]);
            }

            setIsLoading(false);  // API 요청 완료 시 로딩 해제
        })
        .catch(error => {
            console.error('Error fetching profiles:', error);
            setProfiles([]); // 오류 발생 시 profiles 초기화

            setIsLoading(false);  // API 요청 완료 시 로딩 해제
        })      
        ;
    }, [selectedKeywords, univ, selectedSubCategoryState, isIntern, isParttime, isFulltime, categoriesState]);


    useEffect(() => {
        // console.log('fixedContainerRef:', fixedContainerRef.current);
        // console.log('peopleWrapperRef:', peopleWrapperRef.current);

        if (fixedContainerRef.current && peopleWrapperRef.current) {
            updatePaddingTop();
        }
    }, [univ, selectedKeywords, selectedCategoryState]);



    // 윈도우 리사이즈 시 fixed-container 높이 재계산
    useEffect(() => {
        const handleResize = () => updatePaddingTop();

        window.addEventListener('resize', handleResize);
        updatePaddingTop();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 분야 selectbox 핸들러
    const handleCategoryChange = (value) => {
        setSelectedCategoryState(value);
        setSelectedSubCategoryState('');
        setSubCategories(categoriesState[value].map(sub => sub.k_name));
        setSelectedKeywords([]);
        updatePaddingTop();
    };

    // 직무 selectbox 핸들러
    const handleSubCategoryChange = (value) => {
        setSelectedSubCategoryState(value);
        setSelectedKeywords([]);
        updatePaddingTop();
    };

    // 고용구분 필터버튼 핸들러
    const handleApplyChange = (isIntern, isParttime, isFulltime) => {
        setIsIntern(isIntern);
        setIsParttime(isParttime);
        setIsFulltime(isFulltime);
    };

    // 프로필 클릭 핸들러
    const handleProfileSelect = (profile) => {
        navigate('/details-page', { state: { selectedProfile: profile, profiles, logo: profile.logo } });
    };

    const renderProfiles = () => {
        return profiles.map((profile, index) => (
            <ProfileCard
                key={index}
                nickname={profile.person.nickname}
                keywords={profile.keywords}
                univ={profile.person.univ}
                major={profile.person.major}
                gradStatus={profile.person.gradStatus}
                gradDate={profile.person.gradDate}
                jobName={profile.person.jobName}
                apply_Intern={profile.person.apply_Intern}
                apply_Parttime={profile.person.apply_Parttime}
                apply_Fulltime={profile.person.apply_Fulltime}
                mainCareer={profile.mainCareer}
                onClick={() => handleProfileSelect(profile)}
            />
        ));
    };

    const renderProfilesWithUniv = () => {
        return profiles.map((profile, index) => (
            <ProfileCard
                key={index}
                nickname={profile.person.nickname}
                keywords={profile.keywords}
                univ={null}
                major={profile.person.major}
                gradStatus={profile.person.gradStatus}
                gradDate={profile.person.gradDate}
                jobName={profile.person.jobName}
                apply_Intern={profile.person.apply_Intern}
                apply_Parttime={profile.person.apply_Parttime}
                apply_Fulltime={profile.person.apply_Fulltime}
                mainCareer={profile.mainCareer}
                onClick={() => handleProfileSelect(profile)}
            />
        ));
    };

    // Univ 서치 컨테이너 뒷배경 심볼
    const univSymbol = () => {
        const univName = profiles[0]?.person.univ;

        if (univName === '고려대학교') {
            return KU_symbol;
        } else {
            return null;
        }
    };

    // UNIV 값 제거 핸들러
    const handleRemoveUniv = () => {
        localStorage.removeItem('univ'); // 로컬스토리지에서 univ 값 제거
        //console.log('로컬스토리지 univ : ' + localStorage.getItem('univ'));
        navigate('/main-page', { state: { univ: '', selectedCategory, selectedSubCategory, keywordIds, categories } });
    };


    return (
        <div className="main-page">
            <Header univ={univState} setUniv={setUnivState} />
            <main className="main-content">
                <div className="main-fixed-container" ref={fixedContainerRef}>
                    <div className='main-job-select-container'>
                        <h1 className='main-job-select-h1'>분야 / 직무</h1>
                        <div className='main-job-select-selectboxs'>
                            <JobFieldSelectBox
                                options={Object.keys(categoriesState)}
                                selectedValue={selectedCategoryState}
                                onChange={handleCategoryChange}
                                placeholder='분야'
                                message="분야를 선택해주세요."
                            />
                            <JobFieldSelectBox
                                options={selectedCategoryState ? subCategories : []}
                                selectedValue={selectedSubCategoryState}
                                onChange={handleSubCategoryChange}
                                placeholder='직무'
                                message="분야를 선택해주세요."
                            />
                        </div>
                    </div>
                    <div className='main-keyword-buttons-container'>
                        <h1 className='main-keyword-buttons-h1'>역량/스킬셋</h1>
                        <div className="keyword-buttons-inner-container">
                            <KeywordButtons
                                key={selectedSubCategoryState}
                                buttons={categoriesState[selectedCategoryState]?.find(sub => sub.k_name === selectedSubCategoryState)?.keywords || []}
                                selectedButtons={selectedKeywords}
                                containerStyle={{ width: '1000px' }}
                                onKeywordChange={setSelectedKeywords}
                            />
                        </div>
                    </div>
                    <div className='main-apply-status-container'>
                        <h1 className='main-apply-status-h1'>고용 구분</h1>
                        <JobApplyButtons
                            buttons={['전체', '인턴', '계약직', '정규직']}
                            onApplyChange={handleApplyChange}
                        />
                    </div>
                </div>
                {   // 케이스 0: isLoadding 값이 true
                        isLoading ? (
                        <div className="loading-message" ref={peopleWrapperRef}> </div>  // 로딩 상태 표시
                    ) :
                        // 케이스 1: univ 값이 null
                        !univ ? (
                        profiles.length === 0 ? (
                            <div className='main-people-none-container' ref={peopleWrapperRef}>
                                <h1 className='main-people-none-h1'>아쉽지만 아직 해당 직무에 해당하는 인재가 없어요 😢</h1>
                                <MainPageWithNoResult/>
                            </div>
                        ) : (
                            <div className='main-people-wrapper' ref={peopleWrapperRef}>
                                <div className='main-people-header'>
                                    <h1 className='main-people-h1'>
                                        우리 회사의 문제를 함께 해결할 인재가 <div className='main-people-count'>{profiles.length}명</div> 검색되었어요!
                                    </h1>
                                </div>
                                <div className='main-people-container'>
                                    {renderProfiles()}
                                </div>
                            </div>
                        )
                    ) : (
                        // 케이스 2: univ 값이 null이 아님
                        responseMessage === 'NO' ? (
                            <div className='main-people-none-container' ref={peopleWrapperRef}>
                                <h1 className='main-people-none-h1'>
                                    <div className='main-people-univ-none'>{univ}</div> 출신의 인재에 대한 정보가 아직은 없어요😢
                                </h1>
                                <h1 className='main-people-none-h2'>혹시 약칭으로 검색하셨나요? 정확한 대학명을 검색해주시면 인재를 찾기 수월해져요.</h1>
                                <h1 className='main-people-none-h3'>고대 (X) 고려, 고려대, 고려대학교 (O)</h1>
                                <button className='main-people-none-button' onClick={handleRemoveUniv}>학교 구분 없이 찾아볼게요.</button>
                            </div>
                        ) : profiles.length === 0 ? (
                            <div className='main-people-none-container' ref={peopleWrapperRef}>
                                <h1 className='main-people-none-h1'>아쉽지만 아직 해당 직무에 해당하는 인재가 없어요 😢</h1>
                                <button className='main-people-remove-univ-button' onClick={handleRemoveUniv}>
                                    다른 학교에는 있을지 궁금해요!
                                </button>
                                <MainPageWithNoResult classNmae='main-people-none-icon'/>
                            </div>
                        ) : (
                            <div className='main-people-wrapper' ref={peopleWrapperRef}>
                                <div className='main-people-univ-header'>
                                    {univSymbol() && <img src={univSymbol()} alt={`${univ} Logo`} className="main-people-univ-symbol" />}
                                    <h1 className='main-people-h1'>
                                        <div className='main-people-univ'>{profiles[0].person.univ}</div> 출신의 인재들을 찾고 계신가요?
                                    </h1>
                                    <button className='main-people-remove-univ-button' onClick={handleRemoveUniv}>
                                        다른 학교의 인재들도 보고 싶어요!
                                    </button>
                                </div>
                                <div className='main-people-container'>
                                    {renderProfilesWithUniv()}
                                </div>
                            </div>
                        )
                    )}
            </main>
            <Footer />
        </div>
    );
};

export default MainPage;
