import React, { useState, useEffect, useRef } from 'react';
import '../styles/DropDownComponent.css'; // 필요에 따라 스타일 파일을 가져옵니다.

const DropDownComponent = ({ onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('주요 경험 순');
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(option); // 부모 컴포넌트에 선택된 옵션 전달
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button className="dropdown-button" onClick={toggleDropdown}>
                {selectedOption}
                {isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.9135 11.8016L8.40412 4.20625C8.20412 3.93125 7.79475 3.93125 7.59631 4.20625L2.08537 11.8016C2.07181 11.8202 2.06368 11.8423 2.0619 11.8653C2.06011 11.8883 2.06473 11.9114 2.07525 11.932C2.08577 11.9525 2.10177 11.9697 2.12148 11.9817C2.1412 11.9938 2.16385 12.0001 2.18693 12H3.35881C3.4385 12 3.5135 11.9609 3.56037 11.8969L7.99943 5.77812L12.4385 11.8969C12.4854 11.9609 12.5604 12 12.6401 12H13.8119C13.9135 12 13.9729 11.8844 13.9135 11.8016Z" fill="#222222"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M13.8125 4H12.6406C12.5609 4 12.4859 4.03906 12.439 4.10313L7.99995 10.2219L3.56089 4.10313C3.51402 4.03906 3.43902 4 3.35933 4H2.18745C2.08589 4 2.02652 4.11563 2.08589 4.19844L7.59527 11.7937C7.79527 12.0687 8.20464 12.0687 8.40308 11.7937L13.9125 4.19844C13.9734 4.11563 13.914 4 13.8125 4Z" fill="#222222"/>
                    </svg>
                )}
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    <button className="dropdown-item" onClick={() => handleOptionClick('최신 경험 순')}>최신 경험 순</button>
                    <button className="dropdown-item" onClick={() => handleOptionClick('주요 경험 순')}>주요 경험 순</button>
                </div>
            )}
        </div>
    );
};

export default DropDownComponent;
