import React, { useState, useEffect } from 'react';
import '../styles/KeywordButtonsLarge.css';

const KeywordButtons = ({ buttons, containerStyle, buttonClassName, selectedButtons = [], onKeywordChange }) => {
    const [selectedButtonsState, setSelectedButtonsState] = useState(selectedButtons);

    useEffect(() => {
        setSelectedButtonsState(selectedButtons);
    }, [selectedButtons]);

    const handleButtonClick = (button) => {
        let updatedSelectedButtons;
        const isSelected = selectedButtonsState.some(b => b.k_id === button.k_id);

        if (isSelected) {
            updatedSelectedButtons = selectedButtonsState.filter((b) => b.k_id !== button.k_id);
            console.log(button.k_name + ' 버튼 unselected');
        } else {
            updatedSelectedButtons = [...selectedButtonsState, button];
            console.log(button.k_name + ' 버튼 selected');
        }

        setSelectedButtonsState(updatedSelectedButtons);
        onKeywordChange(updatedSelectedButtons);
    };

    return (
        <div className="keyword-button-container-large" style={containerStyle}>
            {buttons.length === 0 && (
                <div className="keyword-button-disabled-large">필요 직무 역량</div>
            )}
            {buttons.map((button, index) => (
                <button
                    key={index}
                    className={`keyword-button-large ${buttonClassName} ${selectedButtonsState.some(b => b.k_id === button.k_id) ? 'selected-large' : ''}`}
                    onClick={() => handleButtonClick(button)}
                >
                    {button.k_name}
                </button>
            ))}
        </div>
    );
};

export default KeywordButtons;
