import React, { useState, useEffect, useCallback } from 'react';
import Header from '../components/Header';
import UnivLogos from '../components/UnivLogos';
import KeywordButtons from '../components/KeywordButtonsLarge';
import Footer from '../components/Footer';
import JobFieldSelectBox from '../components/JobFieldSelectBoxLarge';
import LandingIcon from '../components/LandingIcon';
import '../styles/LandingPage.css';
import axios from 'axios';
import apiClient from '../api/apiClient';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [categories, setCategories] = useState({});
    const [keywords, setKeywords] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const navigate = useNavigate();

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory('');
        setKeywords([]);
        setSelectedKeywords([]);
    };

    const handleSubCategoryChange = (value) => {
        setSelectedSubCategory(value);
    };

    useEffect(() => {
        if (selectedCategory && selectedSubCategory) {
            const subCategoryKeywords = categories[selectedCategory].find(sub => sub.k_name === selectedSubCategory)?.keywords || [];
            setKeywords(subCategoryKeywords);
            setSelectedKeywords([]);
        }
    }, [selectedCategory, selectedSubCategory, categories]);

    const handleKeywordSelection = useCallback((selected) => {
        setSelectedKeywords(selected);
    }, []);

    const handleSearchClick = () => {
        if (selectedKeywords.length === 0) {
            alert('최소 하나의 키워드를 선택해주세요.');
            return;
        }

        const keywordIds = selectedKeywords.map(keyword => keyword.k_id);
        navigate('/main-page', { state: { selectedCategory, selectedSubCategory, keywordIds, categories } });
    };

    const containerStyle = {
        backgroundColor: '#f0f0f0',
        padding: '0px',
        borderRadius: '10px',
        width: '800px',
        boxSizing: 'border-box',
    };

    useEffect(() => {
        console.log('[LandginPage] - /selectField API 요청');
        apiClient.get('/selectField')
            .then(response => {
                const fieldData = response.data.fields;
                const formattedCategories = {};
                fieldData.forEach(field => {
                    formattedCategories[field.k_name] = field.subfields.map(subfield => ({
                        k_name: subfield.k_name,
                        keywords: subfield.keywords
                    }));
                });
                setCategories(formattedCategories);
            })
            .catch(error => {
                console.error('Error fetching field data:', error);
            });
    }, []);

    return (
        <div className="landing-page">
            <Header />
            <main className='landing-main'>
                <h1 className='landing-h1'>어떤 역량/스킬셋을 갖춘 팀원을 찾으시나요?</h1>
                <h1 className='landing-h2'>분야와 상세 직무, 원하는 역량/스킬셋을 선택해주세요.</h1>
                <div className="landing-selectbox-group">
                    <JobFieldSelectBox
                        options={Object.keys(categories)}
                        selectedValue={selectedCategory}
                        onChange={handleCategoryChange}
                        placeholder='분야'
                    />
                    <JobFieldSelectBox
                        options={selectedCategory ? categories[selectedCategory].map(sub => sub.k_name) : []}
                        selectedValue={selectedSubCategory}
                        onChange={handleSubCategoryChange}
                        placeholder='직무'
                        message="분야를 선택해주세요."
                    />
                    <button 
                        className={`landing-selectbox-button ${selectedKeywords.length === 0 ? 'disabled' : 'enabled'}`}
                        selectedButtons={[]}
                        onClick={handleSearchClick}
                        disabled={selectedKeywords.length === 0}
                    >
                        팀원을 찾아주세요!
                    </button>
                </div>
                <div className='landing-keywords-buttons'>
                    <KeywordButtons
                        buttons={keywords}
                        containerStyle={containerStyle}
                        selectedButtons={selectedKeywords}
                        onKeywordChange={handleKeywordSelection}
                    />
                </div>
                <div className='landing-bottom-texts-container'>
                    <h1 className='landing-h3'>채용을 희망하는 분야/직무가 없으신가요?</h1>
                    <div className='landing-h4-container'>
                        <div className='landing-h4-button'>
                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSfxPdCz05YzgYBZPSvSYfMCMNA3L6qma2nzuBHe23SRsjB7hg/viewform?usp=sf_link' className='landing-h4'>채용 희망 직무 신청하기</a>
                            <svg xmlns="http://www.w3.org/2000/svg" >
                                <path d="M12.2465 7.60637L5.20278 2.10481C5.18438 2.09032 5.16226 2.08131 5.13897 2.07883C5.11567 2.07634 5.09215 2.08048 5.0711 2.09076C5.05005 2.10104 5.03233 2.11704 5.01997 2.13694C5.00761 2.15684 5.00111 2.17982 5.00122 2.20325V3.41106C5.00122 3.48762 5.03716 3.56106 5.09653 3.60793L10.7215 8.00012L5.09653 12.3923C5.0356 12.4392 5.00122 12.5126 5.00122 12.5892V13.797C5.00122 13.9017 5.12153 13.9595 5.20278 13.8954L12.2465 8.39387C12.3064 8.34717 12.3548 8.28744 12.3881 8.21921C12.4214 8.15098 12.4387 8.07605 12.4387 8.00012C12.4387 7.92419 12.4214 7.84927 12.3881 7.78104C12.3548 7.71281 12.3064 7.65307 12.2465 7.60637Z" fill="#CECECE"/>
                            </svg>
                        </div>
                    </div>

                    <h1 className='landing-h5'>우리 회사에 딱 맞는 인재와 함께 일하는 기쁨을 누려보세요!</h1>
                    <h1 className='landing-h6'>함께 일하는 의미를 북돋아줄 우수한 인재들이 미닝과 함께 하고 있습니다. </h1>
                    <div className='landing-landingicon'>
                        <LandingIcon />
                    </div>
                    <UnivLogos />
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default LandingPage;
