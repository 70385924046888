import React from 'react';
import '../styles/DetailCareerBox.css'; // 스타일 파일을 맞춰서 변경

const DetailCareerBox = ({
    dateRange,
    title,
    status,
    workContent,
    achievements,
    contributions,
    learnings
}) => {
    // 날짜 형식을 변환하는 함수 정의 (예: 2020-01-01 -> 20.01)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}.${month}`;
    };

    return (
        <div className="detail-box">
            <div className="detail-header">
                <span className="detail-date-range">{formatDate(dateRange.split(' - ')[0])} - {formatDate(dateRange.split(' - ')[1])}</span>
                <div className="detail-header-contents">
                    <span className="detail-title">{title}</span>
                    <span className="detail-status">{status}</span>
                </div>
            </div>
            <div className="detail-content">
                {workContent && workContent !== '업무 내용이 없습니다.' && (
                    <div className="detail-content-section">
                        <span className="detail-label">업무 내용</span>
                        <span className="detail-description">{workContent}</span>
                    </div>
                )}
                {achievements && achievements !== '성과 내용이 없습니다.' && (
                    <>
                        <div className="separator"></div>
                        <div className="detail-content-section">
                            <span className="detail-label">성과</span>
                            <span className="detail-description">{achievements}</span>
                        </div>
                    </>
                )}
                {contributions && contributions !== '기여 내용이 없습니다.' && (
                    <>
                        <div className="detail-content-section">
                            <span className="detail-label">기여한 점</span>
                            <span className="detail-description">{contributions}</span>
                        </div>
                    </>
                )}
                {learnings && learnings !== '배운 점이 없습니다.' && (
                    <>
                        <div className="detail-content-section">
                            <span className="detail-label">배운 점</span>
                            <span className="detail-description">{learnings}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DetailCareerBox;
