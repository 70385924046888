import React from 'react';
import '../styles/Footer.css';
import meaning_w_logo from '../assets/logos/meaning_w_logo.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="164" height="44" viewBox="0 0 164 44" fill="none" className='footer-meaning-logo'>
                    <path d="M152.429 28.6739C153.766 28.6739 154.983 28.2968 156.08 27.5425C157.211 26.7882 158.086 25.7768 158.703 24.5082C159.354 23.2397 159.68 21.8511 159.68 20.3425C159.68 18.8682 159.354 17.4968 158.703 16.2283C158.051 14.9254 157.177 13.8968 156.08 13.1426C154.983 12.3883 153.766 12.0111 152.429 12.0111C150.303 12.0111 148.554 12.7997 147.183 14.3768C145.846 15.9197 145.177 17.9083 145.177 20.3425C145.177 22.8111 145.846 24.8168 147.183 26.3596C148.52 27.9025 150.269 28.6739 152.429 28.6739ZM140.909 20.394C140.909 18.0625 141.389 15.9711 142.349 14.1197C143.343 12.2683 144.697 10.8283 146.411 9.79972C148.126 8.73687 150.08 8.20544 152.274 8.20544C153.749 8.20544 155.154 8.58258 156.491 9.33687C157.829 10.0569 158.891 11.0511 159.68 12.3197V8.20544H164V30.6796C164 33.011 163.503 35.1196 162.509 37.0053C161.549 38.9253 160.16 40.4339 158.343 41.531C156.56 42.6281 154.503 43.1767 152.171 43.1767C150.149 43.1767 148.28 42.7139 146.566 41.7881C144.851 40.8624 143.411 39.4739 142.246 37.6224L145.743 35.411C147.354 38.0167 149.497 39.3196 152.171 39.3196C154.469 39.3196 156.286 38.4967 157.623 36.851C158.994 35.2396 159.68 33.2168 159.68 30.7825V28.2111C158.823 29.4796 157.709 30.5082 156.337 31.2968C154.966 32.0853 153.611 32.4796 152.274 32.4796C150.08 32.4796 148.126 31.9653 146.411 30.9368C144.697 29.9082 143.343 28.4853 142.349 26.6682C141.389 24.8168 140.909 22.7254 140.909 20.394Z" fill="white"/>
                    <path d="M132.361 18.4505C132.361 16.5305 131.846 14.9877 130.818 13.822C129.789 12.6563 128.418 12.0734 126.704 12.0734C124.852 12.0734 123.309 12.7077 122.075 13.9762C120.841 15.2105 120.224 16.7019 120.224 18.4505V32.2333H115.955V8.2677H120.224V11.7648C121.047 10.7363 122.075 9.89626 123.309 9.24484C124.544 8.59341 125.744 8.2677 126.909 8.2677C128.692 8.2677 130.321 8.66198 131.795 9.45055C133.269 10.2391 134.435 11.3877 135.292 12.8963C136.184 14.3705 136.629 16.1191 136.629 18.1419V32.2333H132.361V18.4505Z" fill="white"/>
                    <path d="M109.77 8.20649V32.2235H105.501V8.20649H109.77ZM105.038 2.57142C105.038 1.81713 105.278 1.19999 105.758 0.719995C106.272 0.239998 106.907 0 107.661 0C108.415 0 109.032 0.239998 109.512 0.719995C109.992 1.19999 110.232 1.81713 110.232 2.57142C110.232 3.3257 109.992 3.95998 109.512 4.47427C109.032 4.95426 108.415 5.19426 107.661 5.19426C106.907 5.19426 106.272 4.95426 105.758 4.47427C105.278 3.95998 105.038 3.3257 105.038 2.57142Z" fill="white"/>
                    <path d="M95.7187 18.4505C95.7187 16.5305 95.2044 14.9877 94.1758 13.822C93.1472 12.6563 91.7758 12.0734 90.0615 12.0734C88.2101 12.0734 86.6673 12.7077 85.433 13.9762C84.1987 15.2105 83.5816 16.7019 83.5816 18.4505V32.2333H79.313V8.2677H83.5816V11.7648C84.4044 10.7363 85.433 9.89626 86.6673 9.24484C87.9016 8.59341 89.1015 8.2677 90.2673 8.2677C92.0501 8.2677 93.6787 8.66198 95.1529 9.45055C96.6272 10.2391 97.7929 11.3877 98.6501 12.8963C99.5415 14.3705 99.9872 16.1191 99.9872 18.1419V32.2333H95.7187V18.4505Z" fill="white"/>
                    <path d="M50.7753 32.439C48.5467 32.439 46.5582 31.9247 44.8096 30.8962C43.0953 29.8676 41.741 28.4447 40.7468 26.6276C39.7868 24.7762 39.3068 22.6848 39.3068 20.3534C39.3068 18.0219 39.7868 15.9477 40.7468 14.1305C41.741 12.2791 43.0953 10.8391 44.8096 9.81055C46.5582 8.78198 48.5467 8.2677 50.7753 8.2677C53.8953 8.2677 56.2095 9.03912 57.7181 10.582C59.2267 12.1248 60.2038 13.5305 60.6495 14.7991C61.3352 16.5819 61.6781 18.5362 61.6781 20.6619C61.6781 21.2791 61.6609 21.6905 61.6267 21.8962C61.6267 22.0676 61.6267 22.1705 61.6267 22.2048H43.6267C43.9353 24.0905 44.7582 25.6505 46.0953 26.8848C47.4324 28.0847 48.9924 28.6847 50.7753 28.6847C53.8267 28.6847 56.1067 27.5019 57.6152 25.1362L61.0095 26.8848C60.3238 28.3933 59.0895 29.6962 57.3067 30.7933C55.5581 31.8904 53.381 32.439 50.7753 32.439ZM57.461 18.5019C56.9124 14.182 54.6838 12.022 50.7753 12.022C48.9581 12.022 47.381 12.6391 46.0439 13.8734C44.741 15.0734 43.9353 16.6334 43.6267 18.5534L57.461 18.5019Z" fill="white"/>
                    <path d="M30.2913 17.9362C30.2913 16.1877 29.8284 14.782 28.9027 13.7191C27.977 12.622 26.7599 12.0734 25.2513 12.0734C23.537 12.0734 22.1313 12.5877 21.0342 13.6162C19.9713 14.6105 19.4399 15.9134 19.4399 17.5248V32.2333H15.1199V17.9362C15.1199 16.1877 14.6571 14.782 13.7314 13.7191C12.8399 12.622 11.6571 12.0734 10.1828 12.0734C8.46853 12.0734 7.04568 12.6905 5.91426 13.9248C4.81712 15.1591 4.26855 16.6505 4.26855 18.3991V32.2333H0V8.2677H4.26855V11.7134C4.98855 10.6848 5.89712 9.86198 6.99425 9.24484C8.09139 8.59341 9.20567 8.2677 10.3371 8.2677C11.8114 8.2677 13.2171 8.67913 14.5542 9.50198C15.9256 10.3248 16.9542 11.3877 17.6399 12.6905C18.4285 11.3877 19.5428 10.3248 20.9828 9.50198C22.4228 8.67913 23.8799 8.2677 25.3542 8.2677C27.137 8.2677 28.7313 8.66198 30.137 9.45055C31.5427 10.2391 32.6399 11.3534 33.4284 12.7934C34.217 14.1991 34.6113 15.8105 34.6113 17.6277V32.2333H30.2913V17.9362Z" fill="white"/>
                    <circle cx="70.5574" cy="26.3271" r="3.15929" fill="#87BAE1"/>
                    <circle cx="70.5574" cy="14.7433" r="3.15929" fill="#87BAE1"/>
                    </svg>                
                </div>
                <div className="footer-right">
                    <p className='footer-right-h1'>주식회사 매치워크</p>
                    <p className='footer-right-t1'>대표자 : 권수연</p>
                    <p className='footer-right-t2'>사업자등록번호: 612-87-03096</p>
                    <p className='footer-right-t3'>주소 : 서울창업센터 동작 604호</p>
                    <p className='footer-right-t4'>대표번호 : 070-8985-9443</p>
                    <p className='footer-right-t5'>Email : info@matchwork.co.kr</p>
                    <p className="footer-copyright">Copyright 2024</p>
                </div>
                <div className="footer-links-content">
                    <a href="#" className='footer-personal-info-rules'>개인정보처리방침</a>
                    <a href="#" className='footer-terms-of-uses'>이용약관</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
